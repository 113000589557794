import React, { useState, useEffect, useContext } from "react";
import Global from "../../../context/Global";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import { Modal } from "react-bootstrap";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCookies } from "react-cookie";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../BaseUrl";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const AllpurchaseDesigns = () => {
  const [purchaseDesigns, setPurchaseDesigns] = useState();
  const contextData = useContext(Global);
  const [showPurchaseDesignModal, setShowPurchaseDesignModal] = useState(false);
  const [cookies] = useCookies();
  //   const [isRender, setIsReander] = useState(true);
  const navigate = useNavigate();
  const [purchaseDesignsPagination, setPurchaseDesignsPagination] = useState({
    page: 1,
    page_size: 24,
  });

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    cookies?.user_data &&
      axios
        .post(`${BaseUrl}/client/client_all_buysell_projects`, {
          client_id: cookies?.user_data?.user_id,
          user_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          ...purchaseDesignsPagination,
        })
        .then((res) => {
          if (res?.data?.status === "Success") {
            setPurchaseDesigns(res?.data?.data);

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
  }, [contextData?.showDisclamer, purchaseDesignsPagination]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [purchaseDesignsPagination]);

  const [specificProductData, setSpecificProductData] = useState([]);

  const [specificProductDataType, setSpecificProductDataType] = useState("");
  const [hasVideo, setHasVideo] = useState(false);
  const handleImageAndVideoClick = (images, url, type) => {
    setSpecificProductDataType(type);

    images?.map((res, index) => {
      setSpecificProductData((prev) => [...prev, `${res}`]);
    });
  };
  const purchaseDesignsArray = [];
  for (
    let i = 0;
    i < purchaseDesigns?.total_data / purchaseDesignsPagination?.page_size;
    i++
  ) {
    purchaseDesignsArray?.push(i + 1);
  }

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              <ClientDashboardAside />
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {loading ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <div
                  id="dashboard-menu-bar"
                  className="container  px-md-4 px-3 pt-5"
                >
                  <i
                    className="fa-solid  fa-arrow-left-long ms-5 ms-sm-3 ms-2"
                    onClick={() => {
                      navigate("/clientdashboard", {
                        state: { role: "client", designe: true },
                      });
                    }}
                    style={{
                      color: "#01a78a",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  ></i>

                  <h2 style={{ color: "#01a78a" }} className="text-center">
                    All Designs
                  </h2>

                  <div>
                    <div className="row  pt-5 g-3">
                      {purchaseDesigns?.final_data?.length &&
                        purchaseDesigns?.final_data?.map((res, i, key) => {
                          return (
                            <div className="col-xl-3 col-md-6   my-3" key={i}>
                              <div
                                className="card border-0 flex-row bg-dark text-white visibleForEdit"
                                style={{
                                  height: "240px",
                                  borderRadius: "30px",
                                }}
                              >
                                <img
                                  src={`${res?.cover_image}`}
                                  className="card-img w-100"
                                  style={{
                                    borderRadius: "30px",
                                    objectFit: "cover",
                                    height: "100%",
                                  }}
                                  alt="..."
                                />
                                <div
                                  className="card-img-overlay"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    flexDirection: "column",
                                    borderRadius: "30px",
                                    background:
                                      "linear-gradient(20deg, #000000a1, transparent)",
                                    padding: " 0 10px 20px 10px",
                                  }}
                                >
                                  <h4 className="card-title cardTitleVisible">
                                    ${res?.price}/ project
                                  </h4>

                                  <h4 className="card-title fs-20 visibleForEdit-cat">
                                    {res?.sub_category_name}
                                  </h4>
                                  <div className="row g-2">
                                    <div className="col-xxl-6 col-lg-12 col-6">
                                      <button
                                        type="button"
                                        className="btn btn-primary border-0"
                                        style={{
                                          width: "100%",
                                          fontSize: "14px",
                                          backgroundColor: "rgb(0, 167, 139)",
                                        }}
                                        onClick={() => {
                                          setShowPurchaseDesignModal(true);
                                          handleImageAndVideoClick(
                                            res?.image,
                                            res?.image[0],
                                            "image"
                                          );
                                        }}
                                      >
                                        Preview Images
                                      </button>
                                    </div>
                                    {res?.video == " " ? null : (
                                      <div className="col-xxl-6 col-lg-12 col-6">
                                        <button
                                          type="button"
                                          className="btn btn-primary border-0"
                                          style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            backgroundColor: "rgb(0, 167, 139)",
                                          }}
                                          onClick={() => {
                                            setShowPurchaseDesignModal(true);
                                            handleImageAndVideoClick(
                                              [res?.video],
                                              purchaseDesigns?.video,
                                              "video"
                                            );
                                          }}
                                        >
                                          Preview Videos
                                        </button>
                                      </div>
                                    )}
                                    <div className="col-xxl-6 col-lg-12 col-6">
                                      <NavLink
                                        to="/cart"
                                        state={{
                                          buysell_id: res?.buysell_id,
                                          category_id: res?.category_id,
                                          professional_id: res?.professional_id,
                                          sub_category_id: res?.sub_category_id,
                                          sub_category_name:
                                            res?.sub_category_name,
                                          customize_price: res?.customize_price,
                                          project_cost: res?.price,
                                          video: res?.video,
                                          image: res?.image,
                                          cover_image: res?.cover_image,
                                          page: "AllPurchase",
                                        }}
                                        type="button"
                                        className="btn btn-primary border-0"
                                        style={{
                                          width: "100%",
                                          fontSize: "14px",
                                          backgroundColor: "rgb(0, 167, 139)",
                                        }}
                                      >
                                        Purchase Project
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // );
                            // }
                          );
                        })}
                    </div>
                    {purchaseDesigns?.total_data >= 24 ? (
                      <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                        <Pagination.First
                          onClick={() => {
                            setPurchaseDesignsPagination({
                              page: 1,
                              page_size: 24,
                            });
                          }}
                        />
                        <Pagination.Prev
                          onClick={() => {
                            setPurchaseDesignsPagination((prev) => ({
                              ...prev,
                              page:
                                purchaseDesignsPagination?.page !== 1
                                  ? purchaseDesignsPagination?.page - 1
                                  : 1,
                            }));
                          }}
                        />
                        {purchaseDesignsArray?.map((res, key) => (
                          <Pagination.Item
                            key={key}
                            active={purchaseDesignsPagination?.page === res}
                            onClick={() => {
                              setPurchaseDesignsPagination((prev) => ({
                                ...prev,
                                page: res,
                              }));
                            }}
                          >
                            {res}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => {
                            setPurchaseDesignsPagination((prev) => ({
                              ...prev,
                              page:
                                purchaseDesignsArray?.length !==
                                purchaseDesignsPagination?.page
                                  ? purchaseDesignsPagination?.page + 1
                                  : purchaseDesignsPagination?.page,
                            }));
                          }}
                        />
                        <Pagination.Last
                          onClick={() => {
                            setPurchaseDesignsPagination((prev) => ({
                              ...prev,
                              page: purchaseDesignsArray?.length,
                            }));
                          }}
                        />
                      </Pagination>
                    ) : (
                      purchaseDesignsArray?.length === 0 && (
                        <div
                          style={{ minHeight: "600px" }}
                          className="d-flex justify-content-center align-items-center shadow"
                        >
                          <div className="h4">
                            No Design To Show Related To Your Subcategories
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <Modal
                    className="clientProductDiplayModal"
                    centered
                    // fullscreen={ true }
                    size="xl"
                    animation={true}
                    show={showPurchaseDesignModal}
                    onHide={() => {
                      setShowPurchaseDesignModal(false);
                      setSpecificProductData([]);
                      setSpecificProductDataType("");
                    }}
                  >
                    <button
                      className="modal-closebtn"
                      onClick={() => {
                        setShowPurchaseDesignModal(false);
                        setSpecificProductData([]);
                      }}
                    >
                      <IoMdClose style={{ color: "#fff" }} />
                    </button>

                    <Modal.Body className=" p-0 lebhai_design">
                      <Carousel>
                        {specificProductData &&
                          specificProductData?.map((res) => (
                            <>
                              {specificProductDataType === "image" ? (
                                <img src={res} alt="image" />
                              ) : (
                                <>
                                  <video
                                    width="400"
                                    controls
                                    style={{ maxHeight: "532px" }}
                                    autoplay="true"
                                  >
                                    <source src={res} type="video/mp4" />
                                    <source src={res} type="video/ogg" />
                                    Your browser does not support HTML video.
                                  </video>
                                </>
                              )}
                            </>
                          ))}
                      </Carousel>
                    </Modal.Body>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AllpurchaseDesigns);
