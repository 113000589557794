import React, { useEffect, useState } from "react";
import { ChatHeader } from "../components/Header";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { CiLocationOn } from "react-icons/ci";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
function ReviewPage() {
  const [cookies] = useCookies();

  const [ratData, setRatData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [myProjectPageId, setMyProjectPageId] = useState({
    page: 1,
    page_size: 20,
  });
  useEffect(() => {
    axios
      .post(`${BaseUrl}/client/professional/ratings/`, {
        client_id: cookies?.user_data?.user_id,
        professional_id: location?.state?.professional_id,
        role: cookies?.user_data?.role,
        client_token: cookies?.user_data?.user_token,
        ...myProjectPageId,
      })
      .then((res) => {
        setRatData(res?.data?.data);
      });
  }, [myProjectPageId]);
  const paginationArray = [];
  for (let i = 0; i < ratData?.total_data / myProjectPageId?.page_size; i++) {
    paginationArray.push(i + 1);
  }
  return (
    <>
      <ChatHeader />
      <div className="container">
        <div id="liked-save" className="container-fluid  myProjectTable">
          <h2 className="ps-5"> Ratings </h2>
          <div className="m-lg-5 mx-2 shadow">
            {ratData?.final_data?.length ? (
              ratData?.final_data?.map((res, index) => (
                <div className="row MyProjectDisplayRow" key={index}>
                  <div className="col-md-4  d-flex align-items-center ">
                    <img
                      src={res?.client_image}
                      className="img-fluid rounded-circle"
                      style={{
                        width: "70px",
                        height: "70px",
                        cursor: "pointer",
                      }}
                      alt=""
                    />
                    <div className="ps-3">
                      <h4 className="underline_hover">{res?.client_name}</h4>
                      <h6>
                        <CiLocationOn />
                        {res?.client_location}
                      </h6>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center"
                    style={{ wordBreak: "break-word" }}
                  >
                    {res.review}
                  </div>
                  <div className="col-md-4  d-flex  align-items-center justify-content-end">
                    <Rating
                      name="read-only"
                      value={parseInt(res?.rating)}
                      readOnly
                      precision={0.5}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{ minHeight: "600px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <span className="h4">No Rating Data To Show</span>
              </div>
            )}
          </div>
        </div>

        {ratData && ratData?.total_data > myProjectPageId?.page_size && (
          <Pagination className="ps-5 paginationBoxProfessionalDashboard">
            <Pagination.First
              onClick={() => {
                setMyProjectPageId({
                  page: 1,
                  ...myProjectPageId,
                });
              }}
            />
            <Pagination.Prev
              onClick={() => {
                setMyProjectPageId((prev) => ({
                  ...prev,
                  page:
                    myProjectPageId?.page !== 1 ? myProjectPageId?.page - 1 : 1,
                }));
              }}
            />
            {paginationArray?.map((res, key) => (
              <Pagination.Item
                key={key}
                active={myProjectPageId?.page === res}
                onClick={() => {
                  setMyProjectPageId((prev) => ({
                    ...prev,
                    page: res,
                  }));
                }}
              >
                {res}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => {
                setMyProjectPageId((prev) => ({
                  ...prev,
                  page:
                    paginationArray?.length !== myProjectPageId?.page
                      ? myProjectPageId?.page + 1
                      : myProjectPageId?.page,
                }));
              }}
            />
            <Pagination.Last
              onClick={() => {
                setMyProjectPageId((prev) => ({
                  ...prev,
                  page: paginationArray?.length,
                }));
              }}
            />
          </Pagination>
        )}
      </div>
    </>
  );
}

export default ReviewPage;
