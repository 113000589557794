import React from "react";

const StripeClose = () => {
  const closeWindow = () => {
    // Close the opened window after 3 seconds (for example)
    window.close();
  };

  return (
    <div className="">
      <div class="success-page">
        <img src="/static/images/Logo7.png" alt="pmt" />
        <h2>Your Payment will be processed</h2>

        <div
          className="d-flex justify-content-center mt-4 "
          onClick={closeWindow}
        >
          <p className="backQ-return">Back to Quadra</p>
        </div>
      </div>
    </div>
  );
};

export default StripeClose;
