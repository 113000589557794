import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Checkout from "./components/dashboard_tabs/Client/checkout";
import Login from "./Pages/Login";
import SignIn from "./Pages/Client/SignUp";
import Join from "./Pages/Join";
import SignInSelect from "./Pages/SignInSelect";
import QuadroTerms from "./Pages/Professional/QuadroTerms";
import GetVerified from "./Pages/Professional/GetVerified";
import SetUp from "./Pages/Professional/SetUp";
import KickAssForm from "./Pages/Professional/KickAssForm";
import ProfessionalDashboard from "./Pages/Professional/ProfessionalDashboard";
import TaskForm from "./Pages/Client/TaskForm";
import Chat from "./components/Chat";
import CategoryResult from "./Pages/CategoryResult";
import ClientDashboard from "./Pages/Client/ClientDashboard";
import ProfessionalProfile from "./Pages/ProfessionalProfile";
import ClientCategory from "./Pages/Client/ClientCategories";
import ProfessionalCategoryArchitecture from "./Pages/Professional/ProfessionalCategoryArchitecture";
import ProfessionalCategoryVisualization from "./Pages/Professional/ProfessionalCategoryVisualization";
import { IconContext } from "react-icons";
import Cart from "./Pages/Client/Cart";
import ClientCategoriesArchitectural from "./Pages/Client/ClientCategoriesArchitectural";
import ClientCatagoryVisualization from "./Pages/Client/ClientCatagoryVisualization";
import ClientBuySellDesign from "./Pages/Client/ClientBuySellDesign";
import ProfessionalBuyAndSale from "./Pages/Professional/ProfessionalBuyAndSale";
import Loader from "./components/Loader";
import CheckoutMilstone from "./components/dashboard_tabs/Client/checkoutMilstone";
import ProjectDetails from "./components/ProjectDetails";
import ScrollToTop from "./Hooks/ScrollToTop";
import EditProfile from "./Pages/EditProfile";
import TermsAndCondition from "./Pages/TermsAndCondition";

import BrowsePane from "./components/dashboard_tabs/Client/BrowsePane";
import OngoingPane from "./components/dashboard_tabs/Client/OngoingPane";
import CompletedPane from "./components/dashboard_tabs/Client/CompletedPane";
import Ratings from "./components/dashboard_tabs/Client/Ratings";
import Likes from "./components/dashboard_tabs/Client/Likes";
import ClientGuidlines from "./components/guidelines/ClientGuidlines";
import PortfolioPane from "./components/dashboard_tabs/Professional/PortfolioPane";
import ActivitiesPane from "./components/dashboard_tabs/Professional/ActivitiesPane";
import LikesShow from "./components/dashboard_tabs/Professional/Likes";
import Showratings from "./components/dashboard_tabs/Professional/Ratings";
import ProfessionalGuidelines from "./components/guidelines/ProfessionalGuidelines";
import RequestProjects from "./components/dashboard_tabs/Professional/RequestProjects";
import AcceptProject from "./components/dashboard_tabs/Client/acceptProject";
import { useCookies } from "react-cookie";
import DeclineProject from "./components/dashboard_tabs/Client/DeclineProject";
import PendingProject from "./components/dashboard_tabs/Client/PendingProject";
import DeclinedActivity from "./components/dashboard_tabs/Professional/DeclinedActivity";
import CompletedProject from "./components/dashboard_tabs/Professional/CompletedProject";
import Reject from "./components/project_process/Reject";
import PendingActivity from "./components/dashboard_tabs/Professional/PendingActivity";
import SubscriptionPlans from "./components/dashboard_tabs/Professional/subscription-plans";
import PurchaseDesign from "./components/dashboard_tabs/Client/purchase-design";
import Report from "./components/dashboard_tabs/Professional/Report";
import ReportTab from "./components/dashboard_tabs/Professional/ReportTab";
import LastStep from "./components/dashboard_tabs/Professional/LastStep";
// import Earnings from "./components/dashboard_tabs/Professional/Earnings";
import Billing from "./components/dashboard_tabs/Client/Billing";
import ClientContracts from "./components/dashboard_tabs/Professional/ClientContracts";
import Contract from "./components/dashboard_tabs/Professional/Contract";
import AllProfessionalList from "./components/dashboard_tabs/Client/AllProfessionalList";
import AllpurchaseDesigns from "./components/dashboard_tabs/Client/AllpurchaseDesigns";
import ContractTerms from "./Pages/ContractTerms";
import Blog from "./Pages/BlogMain";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";
import ReviewPage from "./Pages/ReviewPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeClose from "./Pages/StripeClose";
import SubscriptionCheckout from "./components/dashboard_tabs/Professional/SubscriptionCheckout";

const TIMEOUT_DURATION = 30 * 60 * 1000; // 10 minutes in milliseconds
function App() {
  const [stripeApiKey, setstripeApiKey] = useState(
    "pk_live_51NxpCSSGbiLjibuN8K8WzDXzHVkke58qxTpwqHQAc13GRl6NlxAQJPvr9dHG1Y2zSSvkl64dkBgux62ze2tr9r4A00HwBAaDIb"
  );

  // live_key =
  //   "pk_live_51NxpCSSGbiLjibuN8K8WzDXzHVkke58qxTpwqHQAc13GRl6NlxAQJPvr9dHG1Y2zSSvkl64dkBgux62ze2tr9r4A00HwBAaDIb";
  // test_key =
  //   "pk_test_51NxpCSSGbiLjibuNlfcApn0B8Z77aFol2gctAddhKepOWLdIMj2asrT3ycARBDk3xfiDVjH9PuVojzrQNAVocMfD00HYARkNdf";
  const [cookies] = useCookies();
  const [cookiess, , removeCookie] = useCookies();
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const logoutapi = () => {
    axios
      .post(`${BaseUrl}/identity/logout`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
      })
      .then((response) => {
        window.location.reload();
        removeCookie("user_data");
      })
      .catch((error) => {});
  };
  // const handleTabClose = () => {
  //   if (cookies?.user_data) {
  //     logoutapi();
  //   }
  // };

  // // Function to reset the last activity time
  // const resetActivityTime = () => {
  //   setLastActivityTime(Date.now());
  // };

  // // Function to check for inactivity and trigger logout if needed
  // const checkInactivity = () => {
  //   const currentTime = Date.now();
  //   if (currentTime - lastActivityTime >= TIMEOUT_DURATION) {
  //     // Trigger logout API if the user has been inactive for 5 minutes
  //     // logoutapi();
  //   } else {
  //     // If the user is still active, reset the timer
  //     resetActivityTime();
  //     // Schedule the next check after TIMEOUT_DURATION
  //     setTimeout(checkInactivity, TIMEOUT_DURATION);
  //   }
  // };

  // useEffect(() => {
  //   // Set up event listeners to track user activity
  //   const activityEvents = ["mousedown", "keydown", "mousemove", "touchstart"];
  //   activityEvents.forEach((event) => {
  //     window.addEventListener(event, resetActivityTime);
  //   });

  //   // Start the inactivity check when the component mounts
  //   setTimeout(checkInactivity, TIMEOUT_DURATION);

  //   // Clean up event listeners and timers when the component unmounts
  //   return () => {
  //     activityEvents.forEach((event) => {
  //       window.removeEventListener(event, resetActivityTime);
  //     });
  //     clearTimeout(checkInactivity);
  //   };
  // }, []);

  useEffect(() => {
    if (cookies.user_data && cookies.user_data.role === "professional") {
      const handleBeforeUnload = (e) => {
        // Only perform logout when the user is explicitly closing the tab
        if (e.clientY < 0) {
          logoutapi();
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, []);

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <IconContext.Provider
        value={{ color: "black", className: "global-class-name" }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/Loader" element={<Loader />} />
          <Route path="/login" element={<Login />} />
          <Route path="/client-sign-up" element={<SignIn />} />
          <Route path="/join" element={<Join />} />
          <Route path="/select-sign-in" element={<SignInSelect />} />
          <Route path="/quadroterms" element={<QuadroTerms />} />
          <Route path="/setup" element={<SetUp />} />
          <Route path="/kickassform" element={<KickAssForm />} />
          <Route path="/taskform" element={<TaskForm />} />
          <Route path="/blog-page" element={<Blog />} />
          {/* Professional Routes */}
          <Route
            path="/professionaldashboard"
            element={<ProfessionalDashboard />}
          />
          <Route path="/Portfolio" element={<PortfolioPane />} />
          <Route path="/Report" element={<Report />} />
          <Route path="/sold-design" element={<ReportTab />} />
          {/* <Route path="/total-earnings" element={ <Earnings /> } /> */}

          <Route path="/request-projects" element={<RequestProjects />} />
          <Route path="/pending-activity" element={<PendingActivity />} />
          <Route path="/myactivity" element={<ActivitiesPane />} />
          <Route path="/completed-activity" element={<CompletedProject />} />
          <Route path="/declined-activity" element={<DeclinedActivity />} />
          <Route path="/professional-Reject" element={<Reject />} />
          <Route
            path="/subscription-plans"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <SubscriptionPlans />
              </Elements>
            }
          />
          <Route
            path="/subscription-checkout"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <SubscriptionCheckout />
              </Elements>
            }
          />
          <Route path="/like" element={<LikesShow />} />
          <Route path="/last-step" element={<LastStep />} />
          <Route path="/ratings" element={<Showratings />} />
          <Route path="/contract-details/:id" element={<ClientContracts />} />
          <Route path="/contract" element={<Contract />} />

          <Route
            path="/professional-guidelines"
            element={<ProfessionalGuidelines />}
          />

          {/* contractpage  */}

          {/* pending */}
          <Route
            path="/categoryArchitecture"
            element={<ProfessionalCategoryArchitecture />}
          />
          <Route
            path="/categoryvisualization"
            element={<ProfessionalCategoryVisualization />}
          />
          {/* pending */}
          <Route
            path="/professional-buy-and-sale"
            element={<ProfessionalBuyAndSale />}
          />

          {/* Client Routes */}

          <Route path="/clientdashboard" element={<ClientDashboard />} />
          <Route path="/browse-professionals" element={<BrowsePane />} />
          <Route path="/ongoing-projects" element={<OngoingPane />} />
          <Route path="/pending-project" element={<PendingProject />} />
          <Route path="/accept-project" element={<AcceptProject />} />
          <Route path="/completed-projects" element={<CompletedPane />} />
          <Route path="/purchase-design" element={<PurchaseDesign />} />
          <Route path="/declined-projects" element={<DeclineProject />} />
          <Route
            path="/professionalprofile/:professional_id"
            element={<ProfessionalProfile />}
          />
          <Route path="review-page" element={<ReviewPage />} />
          <Route path="/client-liked" element={<Likes />} />
          <Route path="/client-rating" element={<Ratings />} />
          <Route path="/client-guidlines" element={<ClientGuidlines />} />

          {/* <Route path="/cart" element={<Cart />} /> */}
          <Route
            path="/cart"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <Cart />
              </Elements>
            }
          />
          {/* <Route path="/checkout" element={<Checkout />} /> */}

          <Route
            path="/checkout"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <Checkout />
              </Elements>
            }
          />
          {/* <Route path="/check-out" element={<CheckoutMilstone />} /> */}
          <Route
            path="/check-out"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <CheckoutMilstone />
              </Elements>
            }
          />

          <Route path="/billing" element={<Billing />} />

          <Route path="/professionals" element={<AllProfessionalList />} />
          <Route path="/all-designs" element={<AllpurchaseDesigns />} />

          <Route
            path="/client-architechture"
            element={<ClientCategoriesArchitectural />}
          />
          <Route
            path="/client-visualisation"
            element={<ClientCatagoryVisualization />}
          />
          <Route path="/client-buy-sell" element={<ClientBuySellDesign />} />

          {/* both */}
          <Route
            path="/project-details"
            element={
              <Elements stripe={loadStripe(stripeApiKey)}>
                <ProjectDetails />
              </Elements>
            }
          />
          {/* <Route path="/project-details" element={<ProjectDetails />} /> */}
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/chat" element={<Chat />} />

          {/* not now  */}
          <Route path="/clientCategory" element={<ClientCategory />} />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/contract-terms" element={<ContractTerms />} />
          <Route path="/categoryresult" element={<CategoryResult />} />
          <Route path="/getverified" element={<GetVerified />} />

          <Route path="close-stripe-window" element={<StripeClose />} />
        </Routes>
      </IconContext.Provider>
    </BrowserRouter>
  );
}

export default App;
