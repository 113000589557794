import React, { useState, useEffect, useContext } from "react";
import Global from "../../../context/Global";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import { Modal } from "react-bootstrap";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useCookies } from "react-cookie";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../BaseUrl";
import { MultiSelect } from "react-multi-select-component";
import { Form, Formik } from "formik";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const BuyDesign = ({ setBuyDesigns }) => {
  const [showCatagory, setShowCatagory] = useState({});
  const [uploadSubCatagoryModal, setUploadSubCatagoryModal] = useState(false);
  const [catagoriesDropdown, SetCatagoriesDropdown] = useState([]);
  const [purchaseDesigns, setPurchaseDesigns] = useState();
  const contextData = useContext(Global);
  const [showPurchaseDesignModal, setShowPurchaseDesignModal] = useState(false);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [purchaseDesignsPagination, setPurchaseDesignsPagination] = useState({
    page: 1,
    page_size: 24,
  });
  const [loading, setLoading] = useState(false);
  const [catErr, setCatErr] = useState(false);

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  }, []);
  const [clientSelectedCatagory, setClientSelectedCatagory] = useState();

  const uploadCatagory = () => {
    axios
      .post(`${BaseUrl}/client/client_selected_cat`, {
        client_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setClientSelectedCatagory([res?.data?.response]);
          axios
            .post(`${BaseUrl}/client/client_buysell_projects`, {
              client_id: cookies?.user_data?.user_id,
              user_token: cookies?.user_data?.user_token,
              role: cookies?.user_data?.role,
              ...purchaseDesignsPagination,
            })
            .then((res) => {
              if (res?.data?.status === "Success") {
                setPurchaseDesigns(res?.data?.data);

                setLoading(false);
              } else {
                setLoading(false);
              }
            });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    cookies?.user_data &&
      axios
        .post(`${BaseUrl}/client/client_buysell_projects`, {
          client_id: cookies?.user_data?.user_id,
          user_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          ...purchaseDesignsPagination,
        })
        .then((res) => {
          if (res?.data?.status === "Success") {
            setPurchaseDesigns(res?.data?.data);

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
  }, [contextData?.showDisclamer, purchaseDesignsPagination]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [purchaseDesignsPagination]);

  const [specificProductData, setSpecificProductData] = useState([]);
  const [specificProductDataType, setSpecificProductDataType] = useState("");

  const handleImageAndVideoClick = (images, url, type) => {
    setSpecificProductDataType(type);
    images?.map((res, index) => {
      setSpecificProductData((prev) => [...prev, `${res}`]);
    });
  };
  const purchaseDesignsArray = [];
  for (
    let i = 0;
    i < purchaseDesigns?.total_data / purchaseDesignsPagination?.page_size;
    i++
  ) {
    purchaseDesignsArray?.push(i + 1);
  }

  const languagesBuySale = [
    contextData?.static_buy_sale_design?.data &&
      contextData?.static_buy_sale_design?.data?.filter((ress) => {
        return (
          ress !== "" ||
          null ||
          (clientSelectedCatagory && {
            label: ress?.sub_category,
            value: ress?.sub_category_id,
          })
        );
      }),
  ];
  const newCatagoriesBuySale =
    languagesBuySale[0] &&
    languagesBuySale[0].map((res) => {
      return (
        clientSelectedCatagory && {
          label: res?.sub_category,
          value: res?.sub_category_id,
        }
      );
    });

  const newBuySaleOptionsArray =
    newCatagoriesBuySale &&
    newCatagoriesBuySale?.filter((res) => {
      return res !== false;
    });
  return (
    <div id="dashboard-menu-bar" className="container  px-md-4 px-3 pt-5">
      <i
        className="fa-solid  fa-arrow-left-long ms-5 ms-sm-3 ms-2"
        onClick={() => {
          setBuyDesigns(false);
        }}
        style={{
          color: "#01a78a",
          fontSize: "25px",
          cursor: "pointer",
        }}
      ></i>
      <div className="d-flex justify-content-between mt-3">
        <h2 style={{ color: "#01a78a" }} className="">
          Designs
        </h2>
        <div>
          <Link
            to="/all-designs"
            className="btn  border-1"
            style={{
              fontSize: "16px",
              color: "rgb(0, 167, 139)",
            }}
          >
            View All
          </Link>
          <button
            className="btn btn-primary border-0 ms-3"
            style={{
              fontSize: "14px",
              backgroundColor: "rgb(0, 167, 139)",
            }}
            onClick={() => {
              uploadCatagory();
              setUploadSubCatagoryModal(true);
              // setShowCatagory( {
              //   ...showCatagory,
              //   isShowCatagory: true,
              // } );
              setShowCatagory({
                catagory: "purchase",
                isShowCatagory: false,
                catagory_id: 3,
              });
              SetCatagoriesDropdown([]);
            }}
          >
            Update Category
          </button>
        </div>
      </div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div>
          <div className="row  pt-5 g-3">
            {purchaseDesigns?.final_data?.length &&
              purchaseDesigns?.final_data?.map((res, i, key) => {
                return (
                  <div className="col-xl-3 col-md-6   my-3" key={i}>
                    <div
                      className="card border-0 flex-row bg-dark text-white visibleForEdit"
                      style={{ height: "240px", borderRadius: "30px" }}
                    >
                      <img
                        src={res?.cover_image}
                        className="card-img w-100"
                        style={{
                          borderRadius: "30px",
                          objectFit: "cover",
                          height: "100%",
                        }}
                        alt="..."
                      />
                      <div
                        className="card-img-overlay"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          borderRadius: "30px",
                          background:
                            "linear-gradient(20deg, #000000a1, transparent)",
                          padding: " 0 10px 20px 10px",
                        }}
                      >
                        <h4 className="card-title cardTitleVisible">
                          ${res?.price}/ project
                        </h4>

                        <h4 className="card-title fs-20 visibleForEdit-cat">
                          {res?.sub_category_name}
                        </h4>
                        <div className="row g-2">
                          <div className="col-xxl-6 col-lg-12 col-6">
                            <button
                              type="button"
                              className="btn btn-primary border-0"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                backgroundColor: "rgb(0, 167, 139)",
                              }}
                              onClick={() => {
                                setShowPurchaseDesignModal(true);
                                handleImageAndVideoClick(
                                  res?.image,
                                  res?.image[0],
                                  "image"
                                );
                              }}
                            >
                              Preview Images
                            </button>
                          </div>
                          {res?.video === " " ||
                          res?.video ===
                            "https://s3.us-east-2.wasabisys.com/quadra/BuySellDesign/videos/" ? null : (
                            <div className="col-xxl-6 col-lg-12 col-6">
                              <button
                                type="button"
                                className="btn btn-primary border-0"
                                style={{
                                  width: "100%",
                                  fontSize: "14px",
                                  backgroundColor: "rgb(0, 167, 139)",
                                }}
                                onClick={() => {
                                  setShowPurchaseDesignModal(true);
                                  handleImageAndVideoClick(
                                    [res?.video],
                                    purchaseDesigns?.video,
                                    "video"
                                  );
                                }}
                              >
                                Preview Videos
                              </button>
                            </div>
                          )}
                          <div className="col-xxl-6 col-lg-12 col-6">
                            <NavLink
                              to="/cart"
                              state={{
                                buysell_id: res?.buysell_id,
                                category_id: res?.category_id,
                                professional_id: res?.professional_id,
                                sub_category_id: res?.sub_category_id,
                                sub_category_name: res?.sub_category_name,
                                customize_price: res?.customize_price,
                                project_cost: res?.price,
                                video: res?.video,
                                image: res?.image,
                                cover_image: res?.cover_image,
                              }}
                              type="button"
                              className="btn btn-primary border-0"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                backgroundColor: "rgb(0, 167, 139)",
                              }}
                            >
                              Purchase Project
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {purchaseDesigns?.total_data >= 24 ? (
            <Pagination className="ps-5 paginationBoxProfessionalDashboard">
              <Pagination.First
                onClick={() => {
                  setPurchaseDesignsPagination({
                    page: 1,
                    page_size: 24,
                  });
                }}
              />
              <Pagination.Prev
                onClick={() => {
                  setPurchaseDesignsPagination((prev) => ({
                    ...prev,
                    page:
                      purchaseDesignsPagination?.page !== 1
                        ? purchaseDesignsPagination?.page - 1
                        : 1,
                  }));
                }}
              />
              {purchaseDesignsArray?.map((res, key) => (
                <Pagination.Item
                  key={key}
                  active={purchaseDesignsPagination?.page === res}
                  onClick={() => {
                    setPurchaseDesignsPagination((prev) => ({
                      ...prev,
                      page: res,
                    }));
                  }}
                >
                  {res}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => {
                  setPurchaseDesignsPagination((prev) => ({
                    ...prev,
                    page:
                      purchaseDesignsArray?.length !==
                      purchaseDesignsPagination?.page
                        ? purchaseDesignsPagination?.page + 1
                        : purchaseDesignsPagination?.page,
                  }));
                }}
              />
              <Pagination.Last
                onClick={() => {
                  setPurchaseDesignsPagination((prev) => ({
                    ...prev,
                    page: purchaseDesignsArray?.length,
                  }));
                }}
              />
            </Pagination>
          ) : (
            purchaseDesignsArray?.length === 0 && (
              <div
                style={{ minHeight: "600px" }}
                className="d-flex justify-content-center align-items-center shadow"
              >
                <div className="h4">
                  No Design To Show Related To Your Subcategories
                </div>
              </div>
            )
          )}
        </div>
      )}
      <Modal
        className="clientProductDiplayModal"
        centered
        // fullscreen={ true }
        size="xl"
        animation={true}
        show={showPurchaseDesignModal}
        onHide={() => {
          setShowPurchaseDesignModal(false);
          setSpecificProductData([]);
          setSpecificProductDataType("");
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className=" p-0 lebhai_design">
          <Carousel>
            {specificProductData &&
              specificProductData?.map((res) => (
                <>
                  {specificProductDataType === "image" ? (
                    <img src={res} alt="image" />
                  ) : (
                    <video
                      width="400"
                      controls
                      style={{ maxHeight: "532px" }}
                      autoplay="true"
                    >
                      <source src={res} type="video/mp4" />
                      <source src={res} type="video/ogg" />
                      Your browser does not support HTML video.
                    </video>
                  )}
                </>
              ))}
          </Carousel>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        centered
        show={uploadSubCatagoryModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="modalProfessionalDashboard"
      >
        <Modal.Header
          closeButton
          style={{ margin: "0 0 0 auto" }}
          onClick={() => {
            setCatErr(false);
            setUploadSubCatagoryModal(false);
          }}
          className="border-0"
        ></Modal.Header>
        <Modal.Body>
          <h4>Update Purchase Design Catagories </h4>
          <div className="row">
            <div>
              {
                <>
                  <Formik
                    initialValues={{
                      new_sub_cat: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (catagoriesDropdown?.length < 1) {
                        setCatErr(true);
                        return false;
                      }
                      axios
                        .post(`${BaseUrl}/client/add_client_cat`, {
                          client_id: cookies?.user_data?.user_id,
                          user_token: cookies?.user_data?.user_token,
                          role: cookies?.user_data?.role,
                          category_id: showCatagory?.catagory_id,
                          new_sub_cat: values?.new_sub_cat,
                        })
                        .then((res) => {
                          if (res?.data?.status === "Success") {
                            setUploadSubCatagoryModal(false);
                            uploadCatagory();
                            setShowCatagory({
                              ...showCatagory,
                              isShowCatagory: true,
                            });
                          }
                        });
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          <div className="col">
                            {showCatagory?.catagory === "purchase" && (
                              <MultiSelect
                                value={catagoriesDropdown && catagoriesDropdown}
                                options={
                                  newBuySaleOptionsArray &&
                                  newBuySaleOptionsArray
                                }
                                onChange={(catagoriesDropdown) => {
                                  setCatErr(false);
                                  setFieldValue(
                                    "new_sub_cat",
                                    catagoriesDropdown?.map((val) => val?.value)
                                  );
                                  SetCatagoriesDropdown(catagoriesDropdown);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {catErr ? (
                          <p className="text-danger mt-3">
                            Minimum one catagory required
                          </p>
                        ) : (
                          ""
                        )}
                        <div className="row mt-5 pb-5">
                          <div className="col-sm d-flex justify-content-sm-end">
                            <button
                              type="button"
                              className="ModalCategorySubmit mx-0"
                              onClick={() => {
                                setCatErr(false);
                                setShowCatagory({
                                  ...showCatagory,
                                  isShowCatagory: true,
                                });
                              }}
                            >
                              Back
                            </button>
                          </div>
                          <div className="col-sm">
                            <button
                              type="submit"
                              className="ModalCategorySubmit mx-auto"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default React.memo(BuyDesign);
