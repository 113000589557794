import React, { useContext, useEffect, useReducer, useState } from "react";
import { ChatHeader } from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Pagination from "react-bootstrap/Pagination";
import { IoMdClose } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import { NavLink } from "react-router-dom";

import "swiper/css";
import { useCookies } from "react-cookie";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Button } from "react-bootstrap";
import Global from "../context/Global";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import BackButton from "../components/Button/BackButton";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";
const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  height: 250px;
  &:hover > .card-img-overlay {
    background-color: #1a191994;
    opacity: 1;
  }
  img {
    object-fit: cover;
    height: auto;
    max-width: 100%;
  }
  h4 {
    position: absolute;
    top: 10px;
    z-index: 99;
    text-shadow: 1px 1px 5px black;
    right: 10px;
  }
  .card-img-overlay {
    opacity: 0;
    align-items: start;
    z-index: 0;
    display: flex;
    transition: 0.3s ease-in-out;
    justify-content: flex-end;
    flex-direction: column;
  }
`;
const SwiperRow = styled.div`
  .swiper-slide {
    border: 1px solid #00000054;
    border-radius: 7px;
  }
  .swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button-next {
    color: white;
    text-shadow: 1px 2px 8px black;
    width: 45px;
    height: 45px;
    border-radius: 7px;
    &::after {
      font-size: 15px;
    }
    &:hover {
      background-color: #00000091;
      color: white;
    }
  }
  .swiper-button-prev {
    color: white;
    text-shadow: 1px 2px 8px black;
    width: 45px;
    height: 45px;
    border-radius: 7px;
    &::after {
      font-size: 15px;
    }

    &:hover {
      background-color: #00000091;
      color: white;
    }
  }
`;
const initialState = {
  preview_data: null,
  can_upload_video: false,
  preview_data_modal: false,
  preview_data_modal_image: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "PREVIEW_DATA":
      return { ...state, preview_data: action?.value };
    case "CAN_UPLOAD_VIDEO":
      return { ...state, can_upload_video: action?.value };
    case "PREVIEW_DATA_MODAL":
      return { ...state, preview_data_modal: action?.value };
    default:
      return state;
  }
};
const CategoryResult = () => {
  const [show, setShow] = useState(false);
  const [cookies] = useCookies();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [projectPageId, setProjectPageId] = useState({
    page: 1,
    page_size: 12,
  });
  const contextData = useContext(Global);
  const location = useLocation();

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const buttonStyle = {
    padding: "10px 30px",
    borderRadius: "10px",
    border: 0,
  };

  const [bothCatdata, setBothCatdata] = useState([]);

  const showportfoliyoDesign = () => {
    axios
      .post(`${BaseUrl}/client/portfolio_fetch/professional/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        professional_id: location?.state?.professional_id,
        ...projectPageId,
      })
      .then((res) => {
        setBothCatdata(res?.data?.data);
      });
  };
  const ShowPurchase = () => {
    axios
      .post(`${BaseUrl}/client/buysell_fetch/professional/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        professional_id: location?.state?.professional_id,
        ...projectPageId,
      })
      .then((res) => {
        setBothCatdata(res?.data?.data);
      });
  };

  useEffect(() => {
    if (location?.state?.bothCat === "bothCat") {
      return showportfoliyoDesign();
    } else if (location?.state?.bothCat === "Purchase") {
      return ShowPurchase();
    }
  }, [location?.state, projectPageId]);
  const projectPaginationArray = [];
  for (let i = 0; i < bothCatdata?.total_data / projectPageId?.page_size; i++) {
    projectPaginationArray.push(i + 1);
  }
  return (
    <>
      <ChatHeader />

      <div className="container">
        {location?.state && location?.state?.bothCat === "bothCat" && (
          <>
            <div className="Residential-Architecture-page row threeCatData">
              {bothCatdata?.final_data ? (
                bothCatdata?.final_data.map((res, index) => (
                  <div className="col-xl-4 col-xxl-3 col-md-6   my-3">
                    <div className="card border-0 flex-row bg-dark text-white">
                      <img src={res.image} className="card-img" alt="..." />

                      <div className="card-img-overlay">
                        <div className="row g-2">
                          <h5 style={{ fontSize: "20px" }}>
                            {res?.category_name}
                          </h5>
                          <h5 style={{ fontSize: "20px", marginTop: "15%" }}>
                            {res?.project_name}
                          </h5>
                          <div className="col-xxl-6 col-lg-12 col-6">
                            <Button
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                backgroundColor: "#00A78B",
                              }}
                              onClick={() => {
                                dispatch({
                                  type: "PREVIEW_DATA",
                                  value: {
                                    image: res?.image,
                                    type: "image",
                                  },
                                });
                                dispatch({
                                  type: "PREVIEW_DATA_MODAL",
                                  value: true,
                                });
                              }}
                            >
                              Preview Image
                            </Button>
                          </div>
                          {res?.has_video == true ? (
                            <div className="col-xxl-6 col-lg-12 col-6">
                              <Button
                                style={{
                                  width: "100%",
                                  fontSize: "14px",
                                  backgroundColor: "#00A78B",
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: "PREVIEW_DATA",
                                    value: {
                                      video: res?.video,
                                      type: "video",
                                    },
                                  });
                                  dispatch({
                                    type: "PREVIEW_DATA_MODAL",
                                    value: true,
                                  });
                                }}
                              >
                                Preview Video
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="nobothCat shdow">
                    <h2>No Portfolio Design Found</h2>
                  </div>
                </>
              )}
              {projectPageId?.page_size < bothCatdata?.total_data ? (
                <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                  <Pagination.First
                    onClick={() => {
                      setProjectPageId({
                        page: 1,
                        page_size: 5,
                      });
                    }}
                  />
                  <Pagination.Prev
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page:
                          projectPageId?.page !== 1
                            ? projectPageId?.page - 1
                            : 1,
                      }));
                    }}
                  />
                  {projectPaginationArray?.map((res, key) => (
                    <Pagination.Item
                      key={key}
                      active={projectPageId?.page === res}
                      onClick={() => {
                        setProjectPageId((prev) => ({
                          ...prev,
                          page: res,
                        }));
                      }}
                    >
                      {res}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page:
                          projectPaginationArray?.length !== projectPageId?.page
                            ? projectPageId?.page + 1
                            : projectPageId?.page,
                      }));
                    }}
                  />
                  <Pagination.Last
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page: projectPaginationArray?.length,
                      }));
                    }}
                  />
                </Pagination>
              ) : (
                ""
              )}
              {/* ///// */}
            </div>
          </>
        )}

        {location?.state && location?.state?.bothCat === "Purchase" && (
          <>
            <div className="Residential-Architecture-page row threeCatData">
              {bothCatdata?.final_data ? (
                bothCatdata?.final_data.map((res, index) => {
                  return (
                    <div className="col-lg-4 col-xl-3 col-md-6   my-3">
                      <div className="card border-0 flex-row bg-dark text-white">
                        <img
                          src={res.cover_image}
                          className="card-img"
                          alt="..."
                        />

                        <div
                          className="card-img-overlay"
                          style={{ display: "grid", placeContent: "center" }}
                        >
                          <div className="row g-2">
                            <h4 class="card-title fs-20  mt-3">
                              {res?.sub_category_name}
                            </h4>
                            <h4 class="card-title fs-20 ">
                              $ {res?.price}/ project
                            </h4>
                            <div className="col-xxl-6 col-lg-12 col-6">
                              <Button
                                style={{
                                  width: "100%",
                                  fontSize: "14px",
                                  backgroundColor: "#00A78B",
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: "PREVIEW_DATA",
                                    value: {
                                      image: res?.image,
                                      type: "imagePurchase",
                                    },
                                  });
                                  dispatch({
                                    type: "PREVIEW_DATA_MODAL",
                                    value: true,
                                  });
                                }}
                              >
                                Preview Image
                              </Button>
                            </div>
                            {res?.has_video == true ? (
                              <div className="col-xxl-6 col-lg-12 col-6">
                                <Button
                                  style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    backgroundColor: "#00A78B",
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: "PREVIEW_DATA",
                                      value: {
                                        video: res?.video,
                                        type: "video",
                                      },
                                    });
                                    dispatch({
                                      type: "PREVIEW_DATA_MODAL",
                                      value: true,
                                    });
                                  }}
                                >
                                  Preview Video
                                </Button>
                              </div>
                            ) : null}
                            <div className="col-xxl-6 col-lg-12 col-6">
                              <NavLink
                                to="/cart"
                                state={{
                                  buysell_id: res?.id,
                                  category_id: res?.category_id,
                                  professional_id:
                                    location?.state?.professional_id,
                                  sub_category_id: res?.sub_category_id,
                                  sub_category_name: res?.sub_category_name,
                                  customize_price: res?.customize_price,
                                  project_cost: res?.price,
                                  video: res?.video,
                                  image: res?.image,
                                  cover_image: res?.cover_image,
                                  page: "personalPurchase",
                                }}
                              >
                                <Button
                                  style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    backgroundColor: "#00A78B",
                                  }}
                                >
                                  Purchase Project
                                </Button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="nobothCat shdow">
                    <h2>No Purchase Design Found</h2>
                  </div>
                </>
              )}
              {projectPageId?.page_size < bothCatdata?.total_data ? (
                <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                  <Pagination.First
                    onClick={() => {
                      setProjectPageId({
                        page: 1,
                        page_size: 12,
                      });
                    }}
                  />
                  <Pagination.Prev
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page:
                          projectPageId?.page !== 1
                            ? projectPageId?.page - 1
                            : 1,
                      }));
                    }}
                  />
                  {projectPaginationArray?.map((res, key) => (
                    <Pagination.Item
                      key={key}
                      active={projectPageId?.page === res}
                      onClick={() => {
                        setProjectPageId((prev) => ({
                          ...prev,
                          page: res,
                        }));
                      }}
                    >
                      {res}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page:
                          projectPaginationArray?.length !== projectPageId?.page
                            ? projectPageId?.page + 1
                            : projectPageId?.page,
                      }));
                    }}
                  />
                  <Pagination.Last
                    onClick={() => {
                      setProjectPageId((prev) => ({
                        ...prev,
                        page: projectPaginationArray?.length,
                      }));
                    }}
                  />
                </Pagination>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
      {/* //////////// */}

      <div className="container d-flex my-5 justify-content-center">
        <BackButton text="back" />
      </div>
      <Modal
        show={state?.preview_data_modal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalProfessionalDashboard le_bhai"
      >
        <button
          className="modal-closebtn"
          onClick={() => {
            dispatch({ type: "PREVIEW_DATA_MODAL", value: false });
            dispatch({ type: "PREVIEW_DATAL", value: null });
          }}
        >
          <IoMdClose style={{ color: "#fff" }} />
        </button>
        {state?.preview_data && state?.preview_data?.type === "image" && (
          <img
            src={state?.preview_data?.image}
            alt=""
            style={{
              maxHeight: "670px",
              objectFit: "contain",
              width: "100%",
              margin: "auto",
            }}
          />
        )}

        {state?.preview_data &&
          state?.preview_data?.type === "imagePurchase" && (
            <div className="multiPreview_image">
              {" "}
              <Carousel>
                {state?.preview_data?.image.map((res, index) => {
                  return (
                    <img
                      src={res}
                      alt=""
                      style={{
                        maxHeight: "670px",
                        objectFit: "contain",
                        width: "100%",
                        margin: "auto",
                      }}
                    />
                  );
                })}
              </Carousel>
            </div>
          )}

        {state?.preview_data &&
          state?.preview_data?.type === "video" &&
          state?.preview_data?.video !== "" &&
          state?.preview_data?.video && (
            <video
              className="h-100"
              src={state?.preview_data?.video}
              controls="true"
              autoplay="true"
              style={{ objectFit: "contain" }}
            ></video>
          )}
      </Modal>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <button
          className="closeBtn"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            borderRadius: "50%",
            padding: "4px 12px",
            backgroundColor: "#01B182",
            border: "none",
            color: "white",
          }}
          onClick={handleClose}
        >
          <IoMdClose style={{ color: "#fff" }} />
        </button>
        {state?.preview_data && state?.preview_data?.type === "image" && (
          <img
            src={state?.preview_data?.image}
            alt=""
            style={{
              maxHeight: "532px",
              objectFit: "contain",
              width: "100%",
              margin: "auto",
            }}
          />
        )}

        {state?.preview_data &&
          state?.preview_data?.type === "video" &&
          state?.preview_data?.video !== "" &&
          state?.preview_data?.video && (
            <video
              className="h-100"
              src={state?.preview_data?.video && state?.preview_data?.video}
              controls="true"
              autoplay="true"
              style={{ objectFit: "contain" }}
            ></video>
          )}
      </Modal>
    </>
  );
};

export default CategoryResult;
